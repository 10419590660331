import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    // if (!this.props.data) return null;

    // const project = this.props.data.project;
    // const github = this.props.data.github;
    // const name = this.props.data.name;
    // const imagelogo = this.props.data.imagelogo;
    // const description = this.props.data.description;

    return (
      <header id="home">
        {/* <ParticlesBg type="circle" bg={true} /> */}
        
        <nav id="nav-wrap">
          <img id="logo" src="logoDrMakowski.svg" alt="dr Robert Makowski"/>
          <div style={{padding:2}} className="buttons">
          <a href="tel: +48 502 551 404"><button className="project-btn">Zadzwoń >></button></a>
          <a href="mailto: r.makowski@wp.pl"><button className="project-btn1">Napisz >></button></a>
          <a href="https://www.google.pl/maps/place/%C5%BBytnia+16,+01-014+Warszawa/@52.2405,20.9793348,17.35z/data=!4m5!3m4!1s0x471ecb7fd5b34e75:0xb319830519fbefc8!8m2!3d52.2406571!4d20.9810035"><button className="github-btn">Jak dojechać >></button></a>
          </div>
          {/* <a style={{margin:10}} href="#" className="button btn project-btn">Jak dojechać >></a>
          <a style={{margin:10}} href="tel:+48 123 456 789" className="bi bi-telephone">Zadzwoń >></a> */}

          {/* <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a> */}
          
          <ul id="nav" className="nav">
            
            {/* <li className="current">
              <a className="smoothscroll" href="#home">
              Start
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
              O nas
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#zakladyprodukcyjne">
              Zakłady produkcyjne
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#hotele">
               Hotele i restauracje
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#klient">
               Klient indywidualny
              </a>
            </li>*/}

            {/* <li>
            <a style={{margin:10}} href={project} className="button btn project-btn">
                  <i></i>Jak dojechać >>
              </a>
            </li> 

            <li>
              <a style={{margin:10}} href={project} className="button btn project-btn">
                  <i></i>Zadzwoń >>
              </a>
            </li> */}

          </ul>
        </nav>
        <video id="myVideo" src="15MB.mp4" type="video/mp4" autoPlay={true} playsInline={true} muted={true} loop={true}/>
        {/* <img style={{marginTop:0}}
              src="logo-01.png"
              alt="Serwis Aqua - profesjonalna pralnia"
            />     */}
        {/* <div className="row banner">
          <div className="banner-text">
            <img style={{marginTop:0}}
              src="logo-01.png"
              alt="Serwis Aqua - profesjonalna pralnia"
            />     */}
            {/* <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
            </Fade> */}
            {/* <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade> */}
            {/* <hr /> */}
            {/* <Fade bottom duration={2000}> */}
              {/* <ul className="social"> */}
                {/* <a style={{margin:10}} href={project} className="button btn project-btn">
                  <i></i>Zadzwoń >>
                </a>
                <a style={{margin:10}} href={github} className="button btn github-btn">
                  <i></i>Jak dojechać >>
                </a> */}
              {/* </ul> */}
            {/* </Fade> */}
          {/* </div>
        </div> */}

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
