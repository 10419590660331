import React, { Component } from "react";
import { Fade } from "react-reveal";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
  'cert2.jpg',
  'cert1.jpg',
  'cert3.jpg',
  'cert4.png',
  'cert5.png',
  'cert6.png', 
  'cert7.png'
];

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    
    // if (!this.props.data) return null;
    // const name = this.props.data.name;
    // const street = this.props.data.address.street;
    // const city = this.props.data.address.city;
    // const state = this.props.data.address.state;
    // const zip = this.props.data.address.zip;
    // const phone = this.props.data.phone;
    // const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade duration={1000}>
          {/* <div className="row"> */}
            <div className="twelve columns">
              <img src="certyfikat.jpg"></img>
            </div>
          <div className="twelve columns">
            <a href="https://www.znanylekarz.pl/robert-makowski-2/ginekolog/warszawa#tab=profile-reviews"><button href="#">Opinie >></button></a>
          </div>
          <div className="twelve columns">
            <button type="button" onClick={() => this.setState({ isOpen: true })}>Certyfikaty >></button>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() => this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length,})}
                onMoveNextRequest={() => this.setState({ photoIndex: (photoIndex + 1) % images.length,})}
              />
            )}
              {/* <a href="https://drive.google.com/file/d/1u7pfVy26GrPLXdLANVTxsNuXQrWGAYtA/view?usp=sharing"><button>Certyfikaty >></button></a> */}
          </div>
          {/* </div> */}
        </Fade>

        {/* <div className="row"> */}
          {/* <Fade duration={1000}>
            <div className="eight columns">
              <form action="" method="post" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Imię i nazwisko <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      E-mail <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">
                      Tytuł<span className="required">*</span>
                      </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Wiadomość <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit">Wyślij</button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Błąd!</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Twoja wiadomość została wysłana, dziękujemy!
                <br />
              </div>
            </div>
          </Fade> */}

          {/* <Fade duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                
                <h4>Dane kontaktowe:</h4>
                <p>
                  Gabinet dr Robert Makowski
                  <br />
                  <span>ul. Zdrowotna 1 </span>|<span> 00-000 Warszawa</span>< br/>
                  <span>telefon: +48 123 456 789 </span>< br/>
                  <span>e-mail: kontakt@usgrobertmakowski.pl</span><br/>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Aplikacja na telefon:</h4>
                <ul id="twitter">
                  <li>
                    <span>
                      <img
                        src="qrcode.png"
                        alt="aplikacja dr Robert Makowski"
                      />
                    </span>
                  </li>
                  <li>
                    <span>
                    <a style={{color: "#fff"}} href="#" className="button btn project-btn">
                  <i></i>POBIERZ APLIKACJĘ >>
                </a>
                    </span>
                  </li>
                  <li>
                    <span>
                    <a style={{color: "#fff"}} href="#" className="button btn project-btn">
                  <i></i>OPINIE PACJENTEK >>
                </a>
                    </span>
                  </li>
                </ul>
              </div>
            </aside>
          </Fade>
        </div> */}
      </section>
    );
  }
}

export default Contact;
